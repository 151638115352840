import React, { Component } from "react";
import Error from "./ErrorMessage";
import Axios from "axios";
import { Icon } from "semantic-ui-react";
import { toast } from "react-toastify";
import Loader from "../../components/Loader/Loader";
import "react-toastify/dist/ReactToastify.css";
import store from "../../redux/store";
import { SET_REGISTERED_EMAIL } from "../../redux/types";
import GLOBALS from "../../constants";
import { SiMaildotru } from "react-icons/si";
import { BiLock } from "react-icons/bi";
import Turnstile from "react-turnstile";

export default class RegisterForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      emailError: {
        status: false,
        message: "User must provide a valid email address",
      },
      emailConfirmation: "",
      emailConfirmationError: {
        status: false,
        message: "Email do not match",
      },
      password: "",
      repassword: "",
      passwordError: false,
      renterpasswordError: false,
      passwordVisibility: false,
      repasswordVisibility: false,
      loading: false,
      turnstileToken: "",
    };
  }

  handleKeyDown = (e) => {
    if (e.keyCode == 13) {
      this.validation();
    }
  };

  passwordVisibility = () => {
    this.setState({ passwordVisibility: !this.state.passwordVisibility });
    if (document.getElementById("password").type == "password") {
      document.getElementById("password").type = "text";
    } else {
      document.getElementById("password").type = "password";
    }
  };

  repasswordVisibility = () => {
    this.setState({
      repasswordVisibility: !this.state.repasswordVisibility,
    });
    if (document.getElementById("repassword").type == "password") {
      document.getElementById("repassword").type = "text";
    } else {
      document.getElementById("repassword").type = "password";
    }
  };

  validation = () => {
    var error = false;
    if (
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        this.state.email
      )
    ) {
      this.setState({ emailError: true });
      this.setState((prevState) => ({
        emailError: {
          ...prevState.emailError,
          status: true,
          message: this.state.emailError.message,
        },
      }));
      error = true;
    } else {
      this.setState({ emailError: false });
    }

    if (this.state.email != this.state.emailConfirmation) {
      this.setState({
        emailConfirmationError: {
          status: true,
          message: "Email do not match",
        },
      });
      error = true;
    } else {
      this.setState({ emailConfirmationError: { status: false, message: "" } });
    }

    if (
      !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{11,}$/.test(
        this.state.password
      )
    ) {
      this.setState({ passwordError: true });
      error = true;
    } else {
      this.setState({ passwordError: false });
    }

    if (this.state.password != this.state.repassword) {
      this.setState({ renterpasswordError: true });
      error = true;
    } else {
      this.setState({ renterpasswordError: false });
    }

    if (!error) {
      this.registerUser();
    }
  };

  registerUser = () => {
    this.setState({ loading: true });
    const username = this.state.email;
    const email = this.state.email;
    const password = this.state.password;
    const reqJson = {
      email: email,
      username: username,
      password: password,
      "g-recaptcha-response": this.state.turnstileToken,
    };
    return fetch(`${GLOBALS.SERVER_URL}wp-json/ng/v1/signup`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(reqJson),
    })
      .then((res) => {
        if (res.ok) {
          store.dispatch({ type: SET_REGISTERED_EMAIL, payload: email });
          this.props.switchModal("verify");
          this.setState({ loading: false });
        } else {
          return res.json();
        }
      })
      .then((resp) => {
        if (resp.message === "email is not verified") {
          this.props.switchModal("login");
          this.props.showModalWarning(
            "Account already exist. Login and Verify your account."
          );
          this.setState({ loading: false });
        } else if (
          resp.message ===
            "Username already exists, please enter another username" ||
          resp.message === "Email already exists"
        ) {
          this.setState({ loading: false });
          this.setState((prevState) => ({
            emailError: {
              ...prevState.emailError,
              status: true,
              message: "Email is already taken",
            },
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  notify = (message) =>
    toast.dark(message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: { backgroundColor: this.props.darkMode ? "#3C3C3C" : "#EDF0F5" },
    });

  render() {
    return (
      <>
        {this.state.loading ? (
          <Loader smallLoader={true} loadingModal="Registering User" />
        ) : (
          <>
            <div className="textfield-container">
              <div className="textfield">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {this.state.emailError.status ? (
                    <Error message={this.state.emailError.message} />
                  ) : null}
                </div>
                <div className="form-group">
                  <input
                    onChange={(e) =>
                      this.setState({ ...this.state, email: e.target.value })
                    }
                    value={this.state.email}
                    type="text"
                    id="email"
                    autoComplete="new-password"
                    placeholder="you@example.com"
                    className={
                      this.state.emailError.status ? "errorField" : null
                    }
                    onKeyDown={this.handleKeyDown}
                  />
                  <SiMaildotru className="input-icon" />
                </div>
              </div>
              <div className="textfield">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {this.state.emailConfirmationError.status ? (
                    <Error
                      message={this.state.emailConfirmationError.message}
                    />
                  ) : null}
                </div>
                <div className="form-group">
                  <input
                    onChange={(e) =>
                      this.setState({
                        ...this.state,
                        emailConfirmation: e.target.value,
                      })
                    }
                    value={this.state.emailConfirmation}
                    type="text"
                    id="emailConfirmation"
                    autoComplete="new-password"
                    placeholder="you@example.com"
                    className={
                      this.state.emailConfirmation.status ? "errorField" : null
                    }
                    onKeyDown={this.handleKeyDown}
                  />
                  <SiMaildotru className="input-icon" />
                </div>
              </div>

              <div className="textfield">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {/* <label for="password" className="title">
										Password
									</label> */}
                  {this.state.passwordError ? (
                    <Error message="Password must contain min 11 characters, at least 1 letter, 1 number and 1 special character" />
                  ) : null}
                </div>
                <div className="passwordField-container">
                  <input
                    type={this.state.passwordVisibility ? "text" : "password"}
                    onChange={(e) =>
                      this.setState({ ...this.state, password: e.target.value })
                    }
                    value={this.state.password}
                    id="password"
                    autoComplete="new-password"
                    placeholder="Create a Password"
                    style={{ paddingRight: 35 }}
                    className={this.state.passwordError ? "errorField" : null}
                    onKeyDown={this.handleKeyDown}
                  />
                  <BiLock className="input-icon" />
                  <div className="eye-icon">
                    {this.state.passwordVisibility ? (
                      <Icon
                        name="eye slash"
                        onClick={this.passwordVisibility}
                      />
                    ) : (
                      <Icon name="eye" onClick={this.passwordVisibility} />
                    )}
                  </div>
                </div>
              </div>

              <div className="textfield">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {/* <label for="repassword" className="title">
										Re-enter Password
									</label> */}
                  {this.state.renterpasswordError ? (
                    <Error message="Passwords do not match" />
                  ) : null}
                </div>

                <div className="passwordField-container">
                  <input
                    type="password"
                    id="repassword"
                    onChange={(e) =>
                      this.setState({
                        ...this.state,
                        repassword: e.target.value,
                      })
                    }
                    value={this.state.repassword}
                    autoComplete="new-password"
                    placeholder="Re-enter Password"
                    style={{ paddingRight: 35 }}
                    className={
                      this.state.renterpasswordError ? "errorField" : null
                    }
                    onKeyDown={this.handleKeyDown}
                  />
                  <BiLock className="input-icon" />
                  <div className="eye-icon">
                    {this.state.repasswordVisibility ? (
                      <Icon
                        name="eye slash"
                        onClick={this.repasswordVisibility}
                      />
                    ) : (
                      <Icon name="eye" onClick={this.repasswordVisibility} />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="turnstile-container">
              <Turnstile
                refreshExpired="auto"
                execution="execute"
                tabIndex={100}
                size="normal"
                theme={this.props.darkMode ? "dark" : "light"}
                sitekey={process.env.REACT_APP_TURNSTILE_KEY}
                onVerify={(token) => this.setState({ turnstileToken: token })}
                onLoad={(widgetId, bound) => {
                  bound.execute();
                }}
              />
            </div>
            <div className="auth-btns" style={{ marginTop: "15px" }}>
              <a className="purple" onClick={this.validation}>
                REGISTER
              </a>
              <a
                className="blue"
                onClick={() => {
                  this.props.switchModal("login");
                  this.props.switchToCard("login");
                }}
              >
                LOGIN
              </a>
            </div>
          </>
        )}
      </>
    );
  }
}
