import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

//packages
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

// import Loader from "../../components/Loader/Loader";
import MenuSkeleton from "../../components/Skeleton/MenuSkeleton";

//components
import SubscribeModal from "../../pages/Subscribe/SubscribeModal";
import Subscription from "../Subscription/Subscription";
// import { isEmpty } from "../../validation/validation";
// refreshMenu
//all category image
import allCategoryImage from "../../assets/img/all-cats-fill.png";

import classNames from "classnames";
import {
  setCategoryOrder,
  getCategoryOrder,
  getCategoryOrderRefresh,
} from "../../api/api";
import { detect } from "detect-browser";
import { useMediaQuery } from "react-responsive";
import store from "../../redux/store";
import {
  SEARCH_INPUT_SHOWING,
  SET_AUTH_CAT_MENU_LIST,
  SET_CATEGORIES_ARR,
} from "../../redux/types";
import DotLoader2 from "../../components/Loader/DotLoader2";
import SubscriptionModal from "../Subscription/SubscriptionModal";
import { toast } from "react-toastify";

const AppSubmenu = (props) => {
  const [state, setState] = useState({ activeIndex: null });
  const [draggableArry, setDraggableArry] = useState([]);
  const canRefreshMenu = useSelector((state) => state.refreshMenu);
  const iconMode = useSelector((state) => state.settings.menuIconMode);
  const darkMode = useSelector((state) => state.settings.darkMode);
  const [saveCategory, setSaveCategory] = useState(false);
  const getCategoriesListOrder_ = () => {
    const controller = new AbortController();
    const signal = controller.signal;
    setSaveCategory(true);
    getCategoryOrder(signal)
      .then((res) => {
        setSaveCategory(false);
        setDraggableArry([
          {
            label: "All Categories",
            command: () => {
              props.history.push(`/`);
            },
            pathname: "/",
            icon: "",
            term_id: 884886,
            color: "#8A8A8A",
            slug: "all",
          },
          ...res.json,
        ]);
        store.dispatch({
          type: SET_AUTH_CAT_MENU_LIST,
          payload: [
            {
              label: "All Categories",
              command: () => {
                props.history.push(`/`);
              },
              pathname: "/",
              icon: "",
              term_id: 884886,
              color: "#8A8A8A",
              slug: "all",
            },
            ...res.json,
          ],
        });
      })
      .catch((err) => {
        setSaveCategory(false);
      });
  };

  const getRefreshedOrderCategory_ = () => {
    const controller = new AbortController();
    const signal = controller.signal;
    setSaveCategory(true);

    let query = `?refresh_nav=${true}`;

    getCategoryOrderRefresh(signal, query)
      .then((res) => {
        // if (res.json.length !== 0) {
        setSaveCategory(false);
        // localStorage.setItem(
        // 	"draggableArry",
        // 	JSON.stringify([
        // 		{
        // 			label: "All Categories",
        // 			command: () => {
        // 				props.history.push(`/`);
        // 			},
        // 			pathname: "/",
        // 			icon: "",
        // 			term_id: 884886,
        // 			color: "#007ad9",
        // 			slug: "all"
        // 		},
        // 		...res.json
        // 	])
        // );
        setDraggableArry([
          {
            label: "All Categories",
            command: () => {
              props.history.push(`/`);
            },
            pathname: "/",
            icon: "",
            term_id: 884886,
            color: "#8A8A8A",
            slug: "all",
          },
          ...res.json,
        ]);
        store.dispatch({
          type: SET_AUTH_CAT_MENU_LIST,
          payload: [
            {
              label: "All Categories",
              command: () => {
                props.history.push(`/`);
              },
              pathname: "/",
              icon: "",
              term_id: 884886,
              color: "#8A8A8A",
              slug: "all",
            },
            ...res.json,
          ],
        });
        // this.setState({ categoriesArr: [...state.categoriesArr, res.json] });
        // } else {
        // 	setDraggableArry(JSON.parse(localStorage.getItem("initialCatList")) || []);
        // 	console.log(" get Category lists error :[catlist] ", JSON.parse(localStorage.getItem("draggableArry")), " : ", props.items);
        // 	// setDraggableArry(props.items);
        // 	setSaveCategory(false);
        // }
      })
      .catch((err) => {
        setSaveCategory(false);
      });
  };

  useEffect(() => {
    if (props.user.user && props.user.user.token) {
      if (canRefreshMenu.canRefreshMenu) {
        getRefreshedOrderCategory_();
      } else {
        getCategoriesListOrder_();
      }
    }
  }, [props.user.user, canRefreshMenu]);

  useEffect(() => {
    if (localStorage.getItem("userToken")) {
      getCategoriesListOrder_();
    }
  }, [localStorage.getItem("userToken")]);

  useEffect(() => {
    if (!localStorage.getItem("userToken")) {
      let soredDraggableArry = localStorage.getItem("draggableArry");
      if (
        !canRefreshMenu.canRefreshMenu &&
        JSON.parse(soredDraggableArry) &&
        JSON.parse(soredDraggableArry).length > 1
      ) {
        if (JSON.parse(soredDraggableArry).length !== props.items.length) {
          setDraggableArry(props.items);
        } else {
          setDraggableArry(JSON.parse(soredDraggableArry));
        }
      } else {
        setDraggableArry(props.items);
      }
    }
  }, [
    props.items,
    canRefreshMenu,
    props.user.user,
    props.categories.authCategories,
    localStorage.getItem("userToken"),
  ]);

  //set category menu label to white when dark mode is on
  const _enterMouse = (e, item) => {
    const customTooltip = document.getElementsByClassName(
      "menuitem-custom-tooltip"
    )[0];
    const listRect = e.target.getBoundingClientRect();
    customTooltip.style.visibility = "visible";
    customTooltip.style.top = `${listRect.top}px`;
    customTooltip.innerText = item.label;
    customTooltip.id = `category-tooltip-${item.term_id || "all"}`;
    customTooltip.style.color = item.color;

    props.setHoveredId(item.term_id);
  };

  const _leaveMouse = (e, item) => {
    const customTooltip = document.getElementsByClassName(
      "menuitem-custom-tooltip"
    )[0];
    customTooltip.style.visibility = "hidden";

    //if dark mode on to white otherwise black
    props.setHoveredId(null);
  };

  const onMenuItemClick = (event, item, index) => {
    //avoid processing disabled items
    if (item.disabled) {
      event.preventDefault();
      return true;
    }

    //execute command
    if (item.pathname) {
      props.history.push(item.pathname);
      // item.command({ originalEvent: event, item: item });
    }

    if (index === state.activeIndex) setState({ ...state, activeIndex: null });
    else setState({ ...state, activeIndex: index });

    if (props.onMenuItemClick) {
      props.onMenuItemClick({
        originalEvent: event,
        item: item,
      });
    }
  };

  const renderLinkContent = (item) => {
    let submenuIcon = item.items && (
      <i className="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>
    );
    let badge = item.badge && (
      <span className="menuitem-badge">{item.badge}</span>
    );

    return (
      <React.Fragment>
        {!iconMode ? (
          item.icon ? (
            <span
              className={`mobile-font category-label label-id-${item.term_id}`}
              style={{ marginLeft: "10px" }}
            >
              {item.label}
            </span>
          ) : (
            <span
              className={`mobile-font category-label label-id-${item.term_id}`}
              style={{}}
            >
              {item.label}
            </span>
          )
        ) : (
          <span
            className={`mobile-font category-label label-id-${item.term_id}`}
            style={{ margin: "0" }}
          ></span>
        )}
        {submenuIcon}
        {badge}
      </React.Fragment>
    );
  };

  const renderLink = (item, i) => {
    let content = renderLinkContent(item);
    let style = {};
    const amIActive = item.pathname === window.location.pathname;
    if (iconMode) {
      style = { left: "-10px" };
    }
    if (item.icon) {
      style = { top: "13px" };
    }
    if (iconMode && item.icon) {
      style = { left: "-10px", top: "11px" };
    }

    return (
      <a
        className={item.icon ? "myAnchorInMenu" : ""}
        href={item.url}
        onClick={(e) => onMenuItemClick(e, item, i)}
        target={item.target}
        style={
          iconMode
            ? { padding: "1.3em", display: "flex", justifyContent: "center" }
            : {}
        }
      >
        <span
          className={darkMode ? "arrow-left" : "arrow-left-white"}
          style={style}
        ></span>
        {item.icon || item.slug === "all" ? (
          iconMode ? (
            <img
              className={"slim-menu-img"}
              alt="slim menu"
              src={item.icon ? item.icon : allCategoryImage}
              style={{ height: "23px", margin: "0" }}
            />
          ) : (
            <span style={item.icon ? {} : { marginRight: "10px" }}>
              <img
                src={item.icon ? item.icon : allCategoryImage}
                style={{ height: "20px" }}
                alt="item"
              />
            </span>
          )
        ) : amIActive ? (
          <i
            className="flaticon-picture-1 flaticon-icon"
            style={{ fontSize: "20px !important" }}
          ></i>
        ) : (
          <i
            className="flaticon-picture flaticon-icon"
            style={{ fontSize: "20px !important" }}
          ></i>
        )}
        {content}
      </a>
    );
  };

  let items =
    draggableArry &&
    draggableArry.map((item, i) => {
      let active = state.activeIndex === i;
      var activePathname = props.location.pathname;
      if (item.pathname) {
        if (i === 0) {
          return (
            <li
              className={
                activePathname === "/"
                  ? `active-menuitem  menuitem-category-${item.term_id}`
                  : `menuitem-category-${item.term_id}`
              }
              onClick={() => {
                store.dispatch({
                  type: SEARCH_INPUT_SHOWING,
                  payload: false,
                });
              }}
              onMouseEnter={(e) => {
                _enterMouse(e, item);
                //  store.dispatch({type:SEARCH_INPUT_SHOWING,payload:false})
              }}
              onMouseLeave={(e) => _leaveMouse(e, item)}
              key={i}
            >
              {renderLink(item, i)}
              {/*<AppSubmenu
            items={item.items}
            onMenuItemClick={props.onMenuItemClick}
            /> */}
            </li>
          );
        } else {
          return (
            <Draggable
              key={item.term_id}
              draggableId={item.term_id.toString()}
              index={i}
            >
              {(provided) => (
                <li
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  className={
                    activePathname === item.pathname
                      ? `active-menuitem  menuitem-category-${item.term_id}`
                      : `menuitem-category-${item.term_id}`
                  }
                  onMouseEnter={(e) => _enterMouse(e, item)}
                  onMouseLeave={(e) => _leaveMouse(e, item)}
                  onClick={() => {
                    store.dispatch({
                      type: SEARCH_INPUT_SHOWING,
                      payload: false,
                    });
                  }}
                  key={i}
                  // style={{ borderStyle: "solid" }}
                >
                  {item.items && props.root === true && (
                    <div className="arrow"></div>
                  )}
                  {renderLink(item, i)}
                  {/* <AppSubmenu
                items={item.items}
                onMenuItemClick={props.onMenuItemClick}
                /> */}
                </li>
              )}
            </Draggable>
          );
        }
      }
      // );
    });

  //colourize the things here
  try {
    for (let categoryLabel of document.getElementsByClassName(
      "category-label"
    )) {
      const categoryId = parseInt(categoryLabel.classList[2].split("-")[2]);

      let shouldBeColorized =
        categoryId === parseInt(props.activeCategoryId) ||
        categoryId === parseInt(props.hoveredId);

      if (shouldBeColorized) {
        const colorCode = props.items.filter((cat) => {
          return cat.term_id === categoryId;
        })[0].color;

        categoryLabel.style.color = categoryId === 884886 ? "gray" : colorCode;
      } else {
        if (props.darkMode) {
          categoryLabel.style.color = "white";
        } else {
          categoryLabel.style.color = "black";
        }
      }
    }
  } catch (e) {
    // console.log(e)
  }

  const handleOnDragEnd = async (result) => {
    //avoid error when the list item is dragged out of boundry
    const cookieConsent = JSON.parse(localStorage.getItem("cookie-consent"));
    if (!cookieConsent || !cookieConsent.necessary) {
      toast.dark("Accept necessary cookies to re-order items", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: { backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5" },
      });
      props.setShowCookieBanner(true);
      return;
    }

    if (!result.destination) return;
    const items = Array.from(draggableArry);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    if (props.user.user && props.user.user.token) {
      const controller = new AbortController();
      const signal = controller.signal;
      const submittingArr = items.filter((data) => {
        return data.label !== "All Categories";
      });
      const catIds = [];
      submittingArr.map((data, index) => {
        return catIds.push(data.term_id);
      });
      setSaveCategory(true);
      await setCategoryOrder("?category_ids=" + catIds.join(","), signal)
        .then((res) => {
          setSaveCategory(false);
          store.dispatch({ type: SET_AUTH_CAT_MENU_LIST, payload: items });
          setDraggableArry(items);
        })
        .catch((err) => {
          setSaveCategory(false);
          throw err;
        });
    } else {
      store.dispatch({ type: SET_CATEGORIES_ARR, payload: items });
      localStorage.setItem("draggableArry", JSON.stringify(items));
      setDraggableArry(items);
    }
  };

  return (
    <>
      {items ? (
        <DragDropContext onDragEnd={handleOnDragEnd}>
          {saveCategory ? (
            <div
              style={{
                display: "flex",
                position: "absolute",
                backgroundColor: props.settings.darkMode
                  ? "rgb(60, 60, 60,0.6)"
                  : "rgba(237, 240, 245,0.5)",
                zIndex: 1000,
                width: "100%",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* <DotLoader2 /> */}
            </div>
          ) : null}
          <Droppable disabled droppableId="characters">
            {(provided) => (
              <ul
                className={props.className}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {props.skeletonActive ? (
                  <MenuSkeleton
                    color={props.settings.darkMode ? "#3C3C3C" : "#EDF0F5"}
                    contentColor={
                      props.settings.darkMode
                        ? "rgba(255,255,255,0.1)"
                        : "rgba(255,255,255,0.7)"
                    }
                  />
                ) : (
                  items
                )}
                {/* {items} */}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      ) : null}
    </>
  );
};

AppSubmenu.defaultProps = {
  className: null,
  items: null,
  onMenuItemClick: null,
  root: false,
};

AppSubmenu.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array,
  onMenuItemClick: PropTypes.func,
  root: PropTypes.bool,
};

const AppMenu = (props) => {
  const [subscribeModalShowing, setShowSubscribeModalShowing] = useState(false);
  const [hoveredId, setHoveredId] = useState(null);
  const [activeCategoryId, setActiveCategoryId] = useState(null);
  const darkMode = useSelector((state) => state.settings.darkMode);

  const sidebarClassName = classNames("layout-menu-container", {
    "layout-sidebar-dark": darkMode,
    "layout-sidebar-light": !darkMode,
  });

  const iconMode = useSelector((state) => state.settings.menuIconMode);
  const [browserName, setBrowserName] = useState("");
  const [isSubVisible, setIsSubVisible] = useState(
    props.settings.subButtonVisible
  );
  const isLaptop = useMediaQuery({ query: "(max-width: 1440px)" });
  const [catNotificationModal, setCatNotificationModal] = useState(false);
  const [notificatinModal, setNotificationModal] = useState(false);
  // const [notificationSubsModalShowing,setNotificationSubsModalShowing] = useState(false)
  //when the route changes
  useEffect(() => {
    setBrowserName(detect());
    setIsSubVisible(props.settings.subButtonVisible);
    try {
      if (props.location.pathname !== "/") {
        const pathnameArr = props.location.pathname.split("/");
        const categorySlug = pathnameArr[2];

        const activeCategory = props.model.filter(
          (cat) => cat.slug === categorySlug
        )[0];
        setActiveCategoryId(activeCategory.term_id);
      } else {
        setActiveCategoryId(884886);
      }
    } catch (e) {
      setActiveCategoryId(null);
    }
  }, [props.location.pathname, props.model, props.settings.subButtonVisible]);

  const _triggerSubsribeModal = () => {
    setShowSubscribeModalShowing(true);
  };

  const _triggerNotificationSubModal = () => {
    setCatNotificationModal(true);
  };

  useEffect(() => {
    setShowSubscribeModalShowing(false);
  }, [props.location.pathname]);

  const _enterMouse = (e) => {
    const customTooltip = document.getElementsByClassName(
      "menuitem-custom-tooltip"
    )[0];
    const listRect = e.target.getBoundingClientRect();
    customTooltip.style.visibility = "visible";
    customTooltip.style.top = `${listRect.top}px`;
    customTooltip.style.color = "gray";
    customTooltip.innerText =
      localStorage.getItem("userToken") || !props.settings.subButtonVisible
        ? "Notification Settings"
        : "Subscribe";
    customTooltip.id = `subscribe-tooltip`;
  };

  const _leaveMouse = (e) => {
    const customTooltip = document.getElementsByClassName(
      "menuitem-custom-tooltip"
    )[0];
    customTooltip.style.visibility = "hidden";
  };

  let iconWidth = {};

  if (iconMode) {
    iconWidth = { width: "3.8%" };
    if (isLaptop) {
      iconWidth = { width: "5.3%" };
    }
    if (browserName.name === "firefox") {
      iconWidth = { width: "3.8%" };
    }
    if (browserName.name === "firefox" && isLaptop) {
      iconWidth = { width: "5.55%" };
    }
  }

  const _triggerIconModal = () => {
    store.dispatch({
      type: "SET_ICONS_LIBRARY_MODAL",
      payload: true,
    });
  };

  return (
    <>
      <div className={sidebarClassName} style={{ minHeight: "100%" }}>
        <div>
          <AppSubmenu
            {...props}
            skeletonActive={props.model.length >= 11 ? false : true}
            items={props.model}
            className="layout-menu"
            onMenuItemClick={props.onMenuItemClick}
            root={true}
            location={props.location}
            match={props.match}
            activeCategoryId={activeCategoryId}
            hoveredId={hoveredId}
            setActiveCategoryId={setActiveCategoryId}
            setHoveredId={setHoveredId}
            darkMode={props.settings.darkMode}
          />
        </div>
        {
          <ul
            className={
              browserName.name === "firefox" && isLaptop
                ? "sub-menu layout-menu layout-menu-fixed firefox"
                : "layout-menu layout-menu-fixed"
            }
            style={iconWidth}
          >
            <li
              className={`sub-btn ${darkMode ? "btm-dark" : "btm-menu"}`}
              onClick={_triggerIconModal}
            >
              <a
                style={
                  iconMode ? { display: "flex", justifyContent: "center" } : {}
                }
              >
                <i className="glyph-icon flaticon-bank" />
                <span style={{ color: "gray" }} className="mobile-font">
                  {!iconMode && "Icon Library"}
                </span>
              </a>
            </li>
          </ul>
        }
        <SubscribeModal
          darkMode={darkMode}
          open={subscribeModalShowing}
          onClose={() => setShowSubscribeModalShowing(false)}
        />
        <SubscriptionModal
          open={catNotificationModal}
          onClose={() => setCatNotificationModal(false)}
        />
      </div>
    </>
  );
};

AppMenu.defaultProps = {
  model: null,
  onMenuItemClick: null,
};

AppMenu.propTypes = {
  model: PropTypes.array,
  onMenuItemClick: PropTypes.func,
  settings: PropTypes.object.isRequired,
};

const mapStatetoProps = (state) => ({
  settings: state.settings,
  user: state.auth,
  categories: state.categories,
});

export default withRouter(connect(mapStatetoProps)(AppMenu));
