import React, { useState, useEffect, useRef, useMemo } from "react";
//from packages
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { Icon, Popup } from "semantic-ui-react";
import CoverSlider from "../../components/CoverSlider/CoverSlider";
//components
import Loader from "../../components/Loader/Loader";
import PostCard from "../../components/PostCard/PostCard";

import _ from "lodash";
//apis
import {
  getPostsByCategory,
  getHomePosts,
  getPostsByIds,
  getUpvotes,
  getBanners,
} from "../../api/api";
import GLOBALS from "../../constants";
// /utils
import store from "../../redux/store";
import {
  UPDATE_TAGS,
  SEARCH_INPUT_SHOWING,
  SET_AUTH_FAVOURITE_POSTS,
  SET_CAT_LIST,
  SET_UPVOTES,
  SET_TAGS_BY_POST_ORDER,
  SET_POST_CARD_DATA_MODAL,
} from "../../redux/types";
import { isEmpty } from "../../validation/validation";
import { MetaDecorator } from "../../components/MetaDecorator/MetaDecorator";
import metaDecoratorData from "../../metaDecoratorData.json";
import BannerHome from "../../assets/img/BannerHome.png";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Axios from "axios";
import DefaultCover from "../../assets/img/default-cover.png";

const NewDashboard = (props) => {
  const slides = [
    {
      image: props.settings.darkMode ? DefaultCover : DefaultCover,
      default: true,
    },
    {
      image: props.settings.darkMode ? DefaultCover : DefaultCover,
      default: true,
    },
    {
      image: props.settings.darkMode ? DefaultCover : DefaultCover,
      default: true,
    },
  ];

  const allRef = React.useRef(null);

  const [isFinished, setIsFinished] = React.useState(false);

  const [homePostsArr, setHomePostsArr] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [pageNo, setPageNo] = React.useState(0);
  const [loadingMore, setLoadingMore] = React.useState(false);
  const [loadingCategory, setLoadingCategory] = React.useState("");
  const darkMode = useSelector((state) => state.settings.darkMode);
  const [browserWindow, setBrowserWindow] = React.useState({});
  const [coverBannerData, setCoverBannerData] = React.useState([...slides]);
  let mostPopularOne = props.mostPopulars.featuredPost;
  let mostRecentOne = props.mostRecents.postsArr[0];

  React.useEffect(() => {
    const controller = new AbortController();

    getBanners("?group=home", controller.signal)
      .then((resp) => {
        if (resp.response.ok) {
          setCoverBannerData([...resp.json]);
        }
      })
      .catch((err) => {
        // console.log(err);
      });

    return () => controller.abort();
  }, []);

  React.useEffect(() => {
    (async () => {
      if (props.user.user && props.user.user.token) {
        await getUpvotes()
          .then((resp) => {
            store.dispatch({ type: SET_UPVOTES, payload: resp.json.posts });
          })
          .catch((err) => {
            // console.log(err);
          });
      } else {
        store.dispatch({ type: SET_UPVOTES, payload: [] });
      }
    })();
  }, [props.user.user]);

  const _openPostModal = (id) => {
    store.dispatch({
      type: SET_POST_CARD_DATA_MODAL,
      payload: { postCardModal: true, activePostCardModal: id },
    });
  };

  React.useEffect(() => {
    (async () => {
      if (localStorage.getItem("userToken")) {
        await getUpvotes()
          .then((resp) => {
            store.dispatch({ type: SET_UPVOTES, payload: resp.json.posts });
          })
          .catch((err) => {
            // console.log(err);
          });
      } else {
        store.dispatch({ type: SET_UPVOTES, payload: [] });
      }
    })();
  }, [localStorage.getItem("userToken")]);

  React.useEffect(() => {
    (async () => {
      if (props.user && props.user.token) {
        store.dispatch({ type: SET_CAT_LIST, payload: [] });
        return Axios({
          url: `${GLOBALS.SERVER_URL}wp-json/ng/v1/favorite-list`,
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
          },
        })
          .then(async (finRes) => {
            if (finRes.status === 200) {
              let ids = [];
              finRes.data.posts.map((data) => {
                if (!isEmpty(data.categories)) {
                  ids.push(data.ID);
                }
              });
              const uniqueIds = _.uniq(ids);

              // get fav posts full details
              await getPostsByIds(`ids=${uniqueIds.join(",")}`)
                .then((finRess) => {
                  // FavPosts = finRes.json.posts;
                  store.dispatch({
                    type: SET_AUTH_FAVOURITE_POSTS,
                    payload: finRess.json.posts,
                  });
                  // setPostsArr(finRess.json.posts);
                  // setLoading(false);
                })
                .catch((err) => {
                  // console.log(err);
                  setLoading(false);
                });
            } else {
              setLoading(false);
            }
          })
          .catch((err) => {
            // console.log(err);
          });
      }
    })();
  }, [props.user]);

  React.useEffect(() => {
    setBrowserWindow(props.isNewWindowOpen);
  }, [props.isNewWindowOpen]);

  React.useEffect(() => {
    (async () => {
      if (localStorage.getItem("userToken")) {
        store.dispatch({ type: SET_CAT_LIST, payload: [] });
        return Axios({
          url: `${GLOBALS.SERVER_URL}wp-json/ng/v1/favorite-list`,
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
          },
        })
          .then(async (finRes) => {
            if (finRes.status === 200) {
              let ids = [];
              finRes.data.posts.map((data) => {
                if (!isEmpty(data.categories)) {
                  ids.push(data.ID);
                }
              });
              const uniqueIds = _.uniq(ids);

              // get fav posts full details
              await getPostsByIds(`ids=${uniqueIds.join(",")}`)
                .then((finRess) => {
                  // FavPosts = finRes.json.posts;
                  store.dispatch({
                    type: SET_AUTH_FAVOURITE_POSTS,
                    payload: finRess.json.posts,
                  });
                  // setPostsArr(finRess.json.posts);
                  // setLoading(false);
                })
                .catch((err) => {
                  // console.log(err);
                  setLoading(false);
                });
            } else {
              setLoading(false);
            }
          })
          .catch((err) => {
            // console.log(err);
          });
      }
    })();
  }, [localStorage.getItem("userToken")]);
  //on page no change
  React.useEffect(() => {
    // localStorage.clear();
    if (
      isEmpty(JSON.parse(localStorage.getItem("password"))) &&
      process.env.REACT_APP_PASSWORD
    ) {
      props.history.push("/login");
    }

    if (pageNo > 0 && !isFinished) {
      // if (pageNo === 1) {
      // 	try {
      // 		const data = store.getState().world;
      // 		const homePosts = data.homePosts.filter((cat, index) => index < 3);
      // 		setHomePostsArr(() => {
      // 			return homePosts.map((c, i) => {
      // 				return {
      // 					...c,
      // 					posts: c.posts.filter((p, j) => j < 6),
      // 					isFinished: false
      // 				};
      // 			});
      // 		});
      // 	} catch (e) {
      // 		// console.log(e);
      // 	}
      // } else {
      const controller = new AbortController();
      const signal = controller.signal;
      //here is the api call
      getHomePosts(`per_page=3&paged=${pageNo}`, signal)
        .then(async (res) => {
          const newRes = await res.json.categories;
          const lengthNow = homePostsArr.length + newRes.length;

          if (lengthNow >= res.response.headers.get("x-wp-total")) {
            setIsFinished(true);
          }
          return newRes;
        })
        .then(async (res) => {
          if (res.length > 0) {
            await setHomePostsArr([
              ...homePostsArr,
              ...res.map((c, i) => {
                return {
                  ...c,
                  posts: c.posts.filter((p, j) => j < 6),
                  isFinished: false,
                };
              }),
            ]);
            await setLoading(false);
          } else {
            await setIsFinished(true);
          }
        })
        .catch((err) => {
          // console.log(err);
        });
      // }
    }
    // document.getElementsByClassName("card summary post-card");
  }, [pageNo]);

  //inc page no loading of first three categories
  // useEffect(() => {
  // 	if (!isEmpty(props.homePosts)) {
  // 		setPageNo(1);
  // 	}
  // }, [props.homePosts]);

  //did mount
  // React.useEffect(() => {
  // 	document.getElementsByTagName("body")[0].classList.add("home-page");
  // 	document.getElementsByTagName("body")[0].removeAttribute("id");

  // 	var options = {
  // 		root: null,
  // 		rootMargin: "20px",
  // 		threshold: 1.0
  // 	};
  // 	const observer = new IntersectionObserver(handleObserver, options);
  // 	if (allRef.current) {
  // 		observer.observe(allRef.current);
  // 	}
  // 	return () => {
  // 		document.getElementsByTagName("body")[0].classList.remove("home-page");
  // 	};
  // }, []);

  useEffect(() => {
    if (!isFinished) {
      setPageNo((pageNo) => pageNo + 1);
    }
  }, [homePostsArr]);

  //add tags of most recent one and trending one
  useEffect(() => {
    if (mostPopularOne && mostRecentOne && homePostsArr) {
      store.dispatch({ type: UPDATE_TAGS, payload: mostPopularOne.tags });
      store.dispatch({
        type: SET_TAGS_BY_POST_ORDER,
        payload: mostPopularOne.tags,
      });
      store.dispatch({ type: UPDATE_TAGS, payload: mostRecentOne.tags });
      store.dispatch({
        type: SET_TAGS_BY_POST_ORDER,
        payload: mostRecentOne.tags,
      });
      homePostsArr.forEach((categoryData) => {
        categoryData.posts.forEach((post) => {
          store.dispatch({ type: UPDATE_TAGS, payload: post.tags });
          store.dispatch({ type: SET_TAGS_BY_POST_ORDER, payload: post.tags });
        });
      });
    }
  }, [mostPopularOne, mostRecentOne, homePostsArr]);

  // const handleObserver = (entities) => {
  // 	const target = entities[0];
  // 	if (target.isIntersecting) {
  // 		setPageNo((pageNo) => pageNo + 1);
  // 	}
  // };

  const _loadMore = (category) => {
    setLoadingCategory(category.slug);
    setLoadingMore(true);
    const tempRecentPosts = [...homePostsArr];
    const matchedIndex = tempRecentPosts.findIndex(
      (cat) => cat.term_id === category.term_id
    );
    const pageNo =
      parseInt(Math.ceil(tempRecentPosts[matchedIndex].posts.length / 6)) + 1;
    getPostsByCategory(`category=${category.slug}&per_page=6&paged=${pageNo}`)
      .then((res) => {
        const lengthNow = tempRecentPosts[matchedIndex].posts.length + 6;
        let isPostsFinished = false;
        if (lengthNow >= res.response.headers.get("x-wp-total")) {
          isPostsFinished = true;
        }
        tempRecentPosts[matchedIndex] = {
          ...tempRecentPosts[matchedIndex],
          isFinished: isPostsFinished,
          posts: [...tempRecentPosts[matchedIndex].posts, ...res.json.posts],
        };
        setLoadingCategory("");
        setHomePostsArr(tempRecentPosts);
      })
      .catch((err) => {
        // console.log(err);
      })
      .then(() => {
        setLoadingMore(false);
      });
  };

  const changeColor = (e, catId, catColor) => {
    document.getElementById(catId).style.color = catColor;
  };

  const revertColorWhite = (e, catId) => {
    document.getElementById(catId).style.color = "white";
  };

  const revertColorDark = (e, catId) => {
    document.getElementById(catId).style.color = "gray";
  };

  const BigList = useMemo(() => {
    let itemsArray = [...homePostsArr];
    let draggItems = JSON.parse(localStorage.getItem("draggableArry"));
    let sortingArr =
      props.user && props.user.token
        ? props.categories.authCategories
        : draggItems;
    let catsArr = sortingArr ? sortingArr.map((sa) => sa.slug) : [];
    let filteredData = itemsArray.sort(function (a, b) {
      return (
        catsArr.indexOf(a.category_nicename) -
        catsArr.indexOf(b.category_nicename)
      );
    });
    filteredData = filteredData.map((elem) => {
      const catArr =
        props.user && props.user.token
          ? props.categories.authCategories
          : props.categories.categories;
      const cat = catArr
        ? catArr.filter((d) => d.term_id === elem.term_id)
        : [];
      if (!isEmpty(cat)) {
        const catIcon = cat[0] && cat[0].icon ? cat[0].icon : "";
        return { ...elem, icon: catIcon };
      } else {
        return { ...elem };
      }
    });

    return [...new Set([...filteredData])].map((category, index) => {
      const PostBlock =
        category.name !== "Uncategorized" && category.posts.length > 0 ? (
          category.posts.map((post, subIndex) => {
            return [
              <PostCard
                postsArr={category.posts}
                key={subIndex}
                replaceDate={post.attribute}
                // favv={FavPosts && FavPosts.find((postt) => postt.ID === post.ID)}
                upvotedMe={(store.getState().world.upvotedPosts || []).find(
                  (postt) => postt.ID === post.ID
                )}
                postData={post}
                postId={subIndex}
                onOpen={() => _openPostModal(post.ID)}
              />,
            ];
          })
        ) : (
          <div key={"nomore" + index} className="p-col-12 p-lg-12">
            <div
              style={{ textAlign: "center" }}
              className="p-text-center card summary"
            >
              No Links Found
            </div>
          </div>
        );
      const style = {
        color: category.color ? category.color : darkMode ? "white" : "black",
        backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5",
        // boxShadow: `rgba(0, 0, 0, 0.35) 0px 5px 15px`
        boxShadow: `rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px`,
      };

      return [
        <div
          key={index + "p"}
          className="p-col-12 p-lg-12 underlined-border"
          onClick={() => props.history.push("/category/" + category.cat_ID)}
        ></div>,
        ,
        <div
          key={index + "q"}
          className={`p-col-12 p-lg-12 p-text-center category-element-${category.term_id}`}
          style={{ textAlign: "center", margin: "8px auto" }}
        >
          <NavLink className="custom-navlink" to={`/category/${category.slug}`}>
            {category.icon ? (
              <img
                src={category.icon}
                style={{
                  width: "17px",
                  height: "17px",
                  marginRight: "5px",
                  marginBottom: "-3px",
                }}
              />
            ) : null}
            <b
              className="mobile-font"
              onMouseEnter={(e) =>
                changeColor(e, category.cat_ID, category.color)
              }
              onMouseLeave={(e) =>
                darkMode
                  ? revertColorWhite(e, category.cat_ID)
                  : revertColorDark(e, category.cat_ID)
              }
              style={
                darkMode
                  ? {
                      color: "white",
                    }
                  : {
                      color: "gray",
                    }
              }
              id={category.cat_ID}
            >
              {category.cat_name}
            </b>
          </NavLink>
        </div>,
        PostBlock,
        <div
          key={`ell-${index}`}
          style={{ width: "100%", textAlign: "center" }}
        >
          {!category.isFinished && !loadingMore ? (
            <Popup
              trigger={
                <Icon
                  className="custom-navlink"
                  onClick={() => _loadMore(category)}
                  name="ellipsis horizontal"
                  style={{ cursor: "pointer", fontSize: "2em" }}
                />
              }
              content={`More ${category.name}`}
              className={darkMode ? "popupAddonsDark" : "popupAddonsLight"}
              style={style}
              inverted
              position="bottom center"
            />
          ) : loadingCategory !== category.slug ? (
            <Popup
              trigger={
                <Icon
                  className="custom-navlink"
                  name="ellipsis horizontal"
                  onClick={() => _loadMore(category)}
                  style={{ cursor: "pointer", fontSize: "2em" }}
                />
              }
              content={`More ${category.name}`}
              className={darkMode ? "popupAddonsDark" : "popupAddonsLight"}
              style={style}
              inverted
              position="bottom center"
            />
          ) : null}
        </div>,
        loadingMore && loadingCategory === category.slug ? (
          <Loader key={`loader-${index}`} smallLoader={true} />
        ) : null,
        ,
      ];
    });
  }, [
    homePostsArr,
    loadingMore,
    darkMode,
    props.categories.categories,
    props.categories.authCategories,
    localStorage.getItem("userToken"),
  ]);

  const handleScroll = () => {
    // console.log(" handleScroll ", window.innerHeight, window.pageYOffset);
  };

  const _setActiveSlide = (slide) => {
    if (localStorage.getItem("userToken")) {
      store.dispatch({ type: "SET_ACTIVE_SLIDE", payload: slide });
    } else {
      store.dispatch({ type: "SET_UNAUTH_ACTIVE_SLIDE", payload: slide });
    }
  };

  let upme = (store.getState().world.upvotedPosts || []).find(
    (postt) => postt.ID === mostPopularOne.ID
  );
  if (document.getElementById("dashboard")) {
  }

  let isMobile = window.innerWidth <= 992;

  return (
    <div id="dashboard" className="p-grid p-fluid dashboard page-homepage">
      <MetaDecorator metaDataPerRoute={metaDecoratorData.home} />
      {props.settings.bannersMode ? (
        <div
          className={`${
            isMobile ? "CoverBannerMobile" : "CoverBanner"
          } HomeBanner`}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: isEmpty(coverBannerData)
              ? "linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab)"
              : "transparent",
            paddingTop: isEmpty(coverBannerData) ? "20px" : "",
            animation: "gradient 15s ease infinite",
            backgroundSize: "400% 400% !important",
            minHeight: isEmpty(coverBannerData)
              ? isMobile
                ? "20vh"
                : "30vh"
              : "",
            maxHeight: isEmpty(coverBannerData) ? (isMobile ? "20vh" : "") : "",
          }}
        >
          <CoverSlider
            isMobile={isMobile}
            darkMode={darkMode}
            setActiveSlide={_setActiveSlide}
            activeSlide={
              localStorage.getItem("userToken")
                ? props.settings.activeSlide
                : props.settings.unauthActiveSlide
            }
            cattitle={isEmpty(coverBannerData) ? "Discover Something New" : ""}
            slides={coverBannerData}
          />
        </div>
      ) : null}
      {mostRecentOne && mostPopularOne ? (
        <>
          <div
            className="p-col-12"
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "25px",
            }}
          >
            <span
              onClick={() => {
                props.history.push("/popular-links");
              }}
              className={isMobile ? "infocard" : "infocard-desktop"}
              style={{
                marginLeft: isMobile ? "0px" : "10px",
                marginRight: "15px",
              }}
            >
              <NavLink
                onClick={() => {
                  store.dispatch({
                    type: SEARCH_INPUT_SHOWING,
                    payload: false,
                  });
                }}
                className="custom-navlink left"
                to="/popular-links"
              >
                <div className="mobile-font bold">
                  <div className="wrapper">
                    <div className={darkMode ? "cta" : "cta light"} href="#">
                      <span style={{ paddingRight: "3px" }}>
                        <i
                          style={{ fontSize: "20px" }}
                          class="fab fa-gripfire"
                        />
                      </span>
                      <span>Popular Links</span>
                      {/* <span className="change-opacity-svg">
												<svg width="15px" height="20px" viewBox="0 0 66 43" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
													<g id="arrow" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
														<path
															className="one"
															d="M40.1543933,3.89485454 L43.9763149,0.139296592 C44.1708311,-0.0518420739 44.4826329,-0.0518571125 44.6771675,0.139262789 L65.6916134,20.7848311 C66.0855801,21.1718824 66.0911863,21.8050225 65.704135,22.1989893 C65.7000188,22.2031791 65.6958657,22.2073326 65.6916762,22.2114492 L44.677098,42.8607841 C44.4825957,43.0519059 44.1708242,43.0519358 43.9762853,42.8608513 L40.1545186,39.1069479 C39.9575152,38.9134427 39.9546793,38.5968729 40.1481845,38.3998695 C40.1502893,38.3977268 40.1524132,38.395603 40.1545562,38.3934985 L56.9937789,21.8567812 C57.1908028,21.6632968 57.193672,21.3467273 57.0001876,21.1497035 C56.9980647,21.1475418 56.9959223,21.1453995 56.9937605,21.1432767 L40.1545208,4.60825197 C39.9574869,4.41477773 39.9546013,4.09820839 40.1480756,3.90117456 C40.1501626,3.89904911 40.1522686,3.89694235 40.1543933,3.89485454 Z"
															fill="#FFFFFF"
														></path>
														<path
															className="two"
															d="M20.1543933,3.89485454 L23.9763149,0.139296592 C24.1708311,-0.0518420739 24.4826329,-0.0518571125 24.6771675,0.139262789 L45.6916134,20.7848311 C46.0855801,21.1718824 46.0911863,21.8050225 45.704135,22.1989893 C45.7000188,22.2031791 45.6958657,22.2073326 45.6916762,22.2114492 L24.677098,42.8607841 C24.4825957,43.0519059 24.1708242,43.0519358 23.9762853,42.8608513 L20.1545186,39.1069479 C19.9575152,38.9134427 19.9546793,38.5968729 20.1481845,38.3998695 C20.1502893,38.3977268 20.1524132,38.395603 20.1545562,38.3934985 L36.9937789,21.8567812 C37.1908028,21.6632968 37.193672,21.3467273 37.0001876,21.1497035 C36.9980647,21.1475418 36.9959223,21.1453995 36.9937605,21.1432767 L20.1545208,4.60825197 C19.9574869,4.41477773 19.9546013,4.09820839 20.1480756,3.90117456 C20.1501626,3.89904911 20.1522686,3.89694235 20.1543933,3.89485454 Z"
															fill="#FFFFFF"
														></path>
														<path
															className="three"
															d="M0.154393339,3.89485454 L3.97631488,0.139296592 C4.17083111,-0.0518420739 4.48263286,-0.0518571125 4.67716753,0.139262789 L25.6916134,20.7848311 C26.0855801,21.1718824 26.0911863,21.8050225 25.704135,22.1989893 C25.7000188,22.2031791 25.6958657,22.2073326 25.6916762,22.2114492 L4.67709797,42.8607841 C4.48259567,43.0519059 4.17082418,43.0519358 3.97628526,42.8608513 L0.154518591,39.1069479 C-0.0424848215,38.9134427 -0.0453206733,38.5968729 0.148184538,38.3998695 C0.150289256,38.3977268 0.152413239,38.395603 0.154556228,38.3934985 L16.9937789,21.8567812 C17.1908028,21.6632968 17.193672,21.3467273 17.0001876,21.1497035 C16.9980647,21.1475418 16.9959223,21.1453995 16.9937605,21.1432767 L0.15452076,4.60825197 C-0.0425130651,4.41477773 -0.0453986756,4.09820839 0.148075568,3.90117456 C0.150162624,3.89904911 0.152268631,3.89694235 0.154393339,3.89485454 Z"
															fill="#FFFFFF"
														></path>
													</g>
												</svg>
											</span> */}
                    </div>
                  </div>
                </div>
              </NavLink>
            </span>
            <span
              onClick={() => {
                props.history.push("/recent-links");
              }}
              className="infocard"
              style={{
                marginRight: isMobile ? "0px" : "10px",
                marginLeft: "15px",
              }}
            >
              <NavLink
                onClick={() => {
                  store.dispatch({
                    type: SEARCH_INPUT_SHOWING,
                    payload: false,
                  });
                }}
                className="custom-navlink right"
                to="/recent-links"
              >
                <div className="mobile-font bold">
                  <div className="wrapper">
                    <div className={darkMode ? "cta" : "cta light"} href="#">
                      <span style={{ paddingRight: "3px" }}>
                        <i
                          style={{ fontSize: "16px", marginTop: "2px" }}
                          class="far fa-clock"
                        />
                      </span>
                      <span>Recent Links</span>
                      {/* <span className="change-opacity-svg">
												<svg width="15px" height="20px" viewBox="0 0 66 43" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
													<g id="arrow" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
														<path
															className="one"
															d="M40.1543933,3.89485454 L43.9763149,0.139296592 C44.1708311,-0.0518420739 44.4826329,-0.0518571125 44.6771675,0.139262789 L65.6916134,20.7848311 C66.0855801,21.1718824 66.0911863,21.8050225 65.704135,22.1989893 C65.7000188,22.2031791 65.6958657,22.2073326 65.6916762,22.2114492 L44.677098,42.8607841 C44.4825957,43.0519059 44.1708242,43.0519358 43.9762853,42.8608513 L40.1545186,39.1069479 C39.9575152,38.9134427 39.9546793,38.5968729 40.1481845,38.3998695 C40.1502893,38.3977268 40.1524132,38.395603 40.1545562,38.3934985 L56.9937789,21.8567812 C57.1908028,21.6632968 57.193672,21.3467273 57.0001876,21.1497035 C56.9980647,21.1475418 56.9959223,21.1453995 56.9937605,21.1432767 L40.1545208,4.60825197 C39.9574869,4.41477773 39.9546013,4.09820839 40.1480756,3.90117456 C40.1501626,3.89904911 40.1522686,3.89694235 40.1543933,3.89485454 Z"
															fill="#FFFFFF"
														></path>
														<path
															className="two"
															d="M20.1543933,3.89485454 L23.9763149,0.139296592 C24.1708311,-0.0518420739 24.4826329,-0.0518571125 24.6771675,0.139262789 L45.6916134,20.7848311 C46.0855801,21.1718824 46.0911863,21.8050225 45.704135,22.1989893 C45.7000188,22.2031791 45.6958657,22.2073326 45.6916762,22.2114492 L24.677098,42.8607841 C24.4825957,43.0519059 24.1708242,43.0519358 23.9762853,42.8608513 L20.1545186,39.1069479 C19.9575152,38.9134427 19.9546793,38.5968729 20.1481845,38.3998695 C20.1502893,38.3977268 20.1524132,38.395603 20.1545562,38.3934985 L36.9937789,21.8567812 C37.1908028,21.6632968 37.193672,21.3467273 37.0001876,21.1497035 C36.9980647,21.1475418 36.9959223,21.1453995 36.9937605,21.1432767 L20.1545208,4.60825197 C19.9574869,4.41477773 19.9546013,4.09820839 20.1480756,3.90117456 C20.1501626,3.89904911 20.1522686,3.89694235 20.1543933,3.89485454 Z"
															fill="#FFFFFF"
														></path>
														<path
															className="three"
															d="M0.154393339,3.89485454 L3.97631488,0.139296592 C4.17083111,-0.0518420739 4.48263286,-0.0518571125 4.67716753,0.139262789 L25.6916134,20.7848311 C26.0855801,21.1718824 26.0911863,21.8050225 25.704135,22.1989893 C25.7000188,22.2031791 25.6958657,22.2073326 25.6916762,22.2114492 L4.67709797,42.8607841 C4.48259567,43.0519059 4.17082418,43.0519358 3.97628526,42.8608513 L0.154518591,39.1069479 C-0.0424848215,38.9134427 -0.0453206733,38.5968729 0.148184538,38.3998695 C0.150289256,38.3977268 0.152413239,38.395603 0.154556228,38.3934985 L16.9937789,21.8567812 C17.1908028,21.6632968 17.193672,21.3467273 17.0001876,21.1497035 C16.9980647,21.1475418 16.9959223,21.1453995 16.9937605,21.1432767 L0.15452076,4.60825197 C-0.0425130651,4.41477773 -0.0453986756,4.09820839 0.148075568,3.90117456 C0.150162624,3.89904911 0.152268631,3.89694235 0.154393339,3.89485454 Z"
															fill="#FFFFFF"
														></path>
													</g>
												</svg>
											</span> */}
                    </div>
                  </div>
                </div>
              </NavLink>
            </span>
          </div>
          <div
            className={
              props.settings.darkMode
                ? "dashboard-separator"
                : "dashboard-separator-light"
            }
          ></div>
          <PostCard
            featured={true}
            onOpen={() => _openPostModal(mostPopularOne.ID)}
            replaceDate={`Featured ${
              mostPopularOne.attribute ? " |  " + mostPopularOne.attribute : ""
            }`}
            upvotedMe={upme}
            postData={mostPopularOne}
          />
          <PostCard
            newest={true}
            onOpen={() => _openPostModal(mostRecentOne.ID)}
            replaceDate={`Newest ${
              mostRecentOne.attribute ? " |  " + mostRecentOne.attribute : ""
            }`}
            upvotedMe={upme}
            postData={mostRecentOne}
          />
        </>
      ) : null}
      {BigList}

      {isFinished ? (
        <div className="p-col-12 p-lg-12">
          <div
            style={{ textAlign: "center" }}
            className="p-text-center card summary"
          >
            No Results
          </div>
        </div>
      ) : (
        <div
          ref={allRef}
          style={{ display: "block" }}
          className="loading-more-wrapper"
        >
          <Loader loadingMore={true} />
        </div>
      )}
    </div>
  );
};

NewDashboard.propTypes = {
  homePosts: PropTypes.array.isRequired,
  mostRecents: PropTypes.any.isRequired,
  mostPopulars: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => ({
  homePosts: state.world.homePosts,
  mostRecents: state.mostRecents,
  mostPopulars: state.mostPopulars,
  settings: state.settings,
  authFavPosts: state.favourite.authFavPosts,
  favPosts: state.favourite.favPosts,
  user: state.auth.user,
  isNewWindowOpen: state.world.isNewWindowOpen,
  postCardModal: state.world.postCardModal,
  activePostCardModal: state.world.activePostCardModal,
  postModalData: state.world.postModalData,
  unCheckedLinks: state.world.unCheckedLinks,
  categories: state.categories,
  // firstTimeLoad: state.world.firstTimeLoad
});

export default withRouter(connect(mapStateToProps)(NewDashboard));
