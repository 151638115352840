import React from "react";
import logo from "../../assets/img/icon192.png";

function SuspenseLoader() {
	return (
		<div style={{ width: "100%", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
			<img src={logo} style={{ width: "40px", height: "40px" }} />
		</div>
	);
}

export default SuspenseLoader;
