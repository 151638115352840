import React, { useState, useEffect, Suspense } from "react";

//packages
import { connect, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { Route, Switch, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import ManifestData from "./defaultManifest.json";
//components
import Tag from "./pages/Tag/Tag";
import List from "./pages/List/List";
import Post from "./pages/Post/Post";
// import About from "./pages/About/About";
// import Platforms from "./pages/Platforms/Platforms";
// import Lists from "./pages/Lists/Lists";
import AppMenu from "./layout/Menu/AppMenu";
import AddPost from "./pages/AddPost/AddPost";
import TagsBar from "./layout/TagsBar/TagsBar";
import FilterBar from "./layout/FilterBar/FilterBar";
import InfoBar from "./layout/InfoBar/InfoBar";
// import Category from "./pages/Category/Category";
import AppTopbar from "./layout/TopBar/AppTopbar";
import Dashboard from "./pages/Dashboard/Dashboard";
import NewDashboard from "./pages/Dashboard/NewDashboard";
// import Favourites from "./pages/Favourites/Favourites";
// import Playground from "./pages/Playground/Playground";
import LoaderPage from "./components/Loader/LoaderPage";
// import MostRecents from "./pages/MostRecents/MostRecents";
import SearchResult from "./pages/SearchResult/SearchResult";
import SendFeedback from "./pages/SendFeedback/SendFeedback";
// import MostPopulars from "./pages/MostPopulars/MostPopulars";
// import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
// import Faq from "./pages/Faq/Faq";
// import Howto from "./pages/Howto/Howto";
// import Account from "./pages/Account/Account";
import AcceptCookies from "./components/AcceptCookies/AcceptCookies";
// import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import ReactGA from "react-ga";
import RouteChangeTracker from "./RouteChangeTracker.js";
import AccountTagsBar from "./layout/TagsBar/AccountTagsBar";
import Subscription from "./layout/Subscription/Subscription";
import NewRandomLink from "./layout/RandomLink/NewRandomLink";
import Submissions from "./pages/Submissions/Submissions";
import Unsubscribe from "./pages/Unsubscribe/Unsubscribe";
//hooks

//apis
import {
  getCatRandomPost,
  getMostRecents,
  getHomePosts,
  getCategoriesList,
  getMostPopulars,
  getLists,
  getUpvotes,
  getCategoryOrder,
  getFavIcons,
  getLinkTypeData,
  getCookie,
  getLogo,
  getCookieConsent,
} from "./api/api";

//utils
import store from "./redux/store";

//assets

//global

//styles
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import classNames from "classnames";
import "./assets/styles/layout.scss";
import "semantic-ui-css/semantic.min.css";
import "./assets/styles/font/flaticon.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/nova-light/theme.css";
//for overriding
import "./App.scss";
import "react-toastify/dist/ReactToastify.css";
import PostModal from "./pages/Post/PostModal";
import _, { isNumber } from "lodash";

//types
import {
  SET_POST_CARD_DATA_MODAL,
  SET_HOME_POSTS,
  SET_CATEGORIES_ARR,
  SET_MOST_RECENT_ONE,
  SET_MOST_RECENT_POSTS,
  SET_MOST_POPULAR_POSTS,
  SET_MENU_ICON_MODE,
  UPDATE_LISTS,
  SAVE_INSTALL_PROPOSAL_EVENT,
  SET_BROWSER_NAME,
  SET_FEATURED_POSTS,
  SET_SUBSCRIBE_BUTTON_STATUS,
  SET_AUTH_CAT_MENU_LIST,
  SET_FEEDBACK_MODAL,
  PWA_WINDOW_ON,
  SET_LINK_TYPES,
  UNINSTALL_PWA_MODAL,
} from "./redux/types";
import { Icon } from "semantic-ui-react";
import OfflinePage from "./components/OfflinePage/OfflinePage";
import LoginForm from "./layout/LoginForm/LoginForm";
// import { isEmpty } from "./validation/validation";
import Tags from "./pages/Tags/Tags";
import NotFound from "./pages/NotFound/NotFound";
import { useMediaQuery } from "react-responsive";
import { detect } from "detect-browser";
import SendFeedbackModal from "./pages/SendFeedback/SendFeedbackModal";
import UninstallPwaModal from "./pages/PWA/UninstallPwaModal";
import SuspenseLoader from "./components/Loader/SuspenseLoader";
import { isEmpty } from "./validation/validation";
import IconLibraryModal from "./components/IconLibrary/IconLibraryModal";

const About = React.lazy(() => import("./pages/About/About"));
const Platforms = React.lazy(() => import("./pages/Platforms/Platforms"));
const Lists = React.lazy(() => import("./pages/Lists/Lists"));
const Category = React.lazy(() => import("./pages/Category/Category"));
const Favourites = React.lazy(() => import("./pages/Favourites/Favourites"));
const Playground = React.lazy(() => import("./pages/Playground/Playground"));
const MostRecents = React.lazy(() => import("./pages/MostRecents/MostRecents"));
const MostPopulars = React.lazy(() =>
  import("./pages/MostPopulars/MostPopulars")
);
const PrivacyPolicy = React.lazy(() =>
  import("./pages/PrivacyPolicy/PrivacyPolicy")
);
const Faq = React.lazy(() => import("./pages/Faq/Faq"));
const Howto = React.lazy(() => import("./pages/Howto/Howto"));
const Account = React.lazy(() => import("./pages/Account/Account"));
const TermsAndConditions = React.lazy(() =>
  import("./pages/TermsAndConditions/TermsAndConditions")
);
// import SubscribeConfirmation from "./pages/SubscribeConfirmation/SubscribeConfirmation";
// import Cookies from "./pages/Cookies/Cookies";
// import Use from "./pages/Use/Use";
// import Eula from "./pages/Eula/Eula";
// import Disclaimer from "./pages/Disclaimer/Disclaimer";
const SubscribeConfirmation = React.lazy(() =>
  import("./pages/SubscribeConfirmation/SubscribeConfirmation")
);
const Cookies = React.lazy(() => import("./pages/Cookies/Cookies"));
const Use = React.lazy(() => import("./pages/Use/Use"));
const Eula = React.lazy(() => import("./pages/Eula/Eula"));
const Disclaimer = React.lazy(() => import("./pages/Disclaimer/Disclaimer"));

const volatileRoutes = ["", "/post"];

if (localStorage.password) {
  const itemStr = localStorage.getItem("password")
    ? localStorage.getItem("password")
    : "";
  const currentTime = Date.now() / 1000;
  const item = JSON.parse(itemStr);
  if (currentTime > item.expiry) {
    localStorage.removeItem("password");
    window.location.href = "/login";
  }
}

const App = (props) => {
  const [loading, setLoading] = useState(true);
  const [maxCategoriesLength, setMaxCategoriesLength] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [randomPageLoading, setRandomPageLoading] = useState(false);
  const iconMode = useSelector((state) => state.settings.menuIconMode);
  const darkMode = useSelector((state) => state.settings.darkMode);
  const canRefreshMenu = useSelector((state) => state.refreshMenu);
  const [searchPageNumber, setSearchPageNumber] = useState(0);

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isLaptop = useMediaQuery({ query: "(max-width: 1440px)" });
  const [browserName, setBrowserName] = useState("");
  let isMobile = window.innerWidth <= 992;
  const [showCookieBanner, setShowCookieBanner] = useState(false);

  const [state, setState] = useState({
    layoutMode: "static",
    staticMenuInactive: false,
    overlayMenuActive: false,
    mobileMenuActive: false,

    categoriesArr: [
      {
        label: "All Categories",
        command: () => {
          props.history.push(`/`);
        },
        pathname: "/",
        icon: "",
        term_id: 884886,
        color: "#8A8A8A",
        slug: "all",
      },
    ],
  });

  const getCookieContent = (query, signal) => {
    getCookie(query, signal)
      .then((res) => {
        if (res.response.ok) {
          store.dispatch({ type: "SET_COOKIE", payload: res.json.content });
        }
      })
      .catch((err) => {
        // handle error
      });
  };

  const getCookieConsentData = () => {
    getCookieConsent("")
      .then((res) => {
        localStorage.setItem(
          "cookie-consent",
          JSON.stringify({
            necessary: res.json.strictly_necessary_cookie || false,
            perfomance: res.json.performance_and_analytics_cookie || false,
            adv: res.json.advertisement_and_targeting_cookie || false,
          })
        );

        if (
          !res.json.strictly_necessary_cookie &&
          !res.json.performance_and_analytics_cookie &&
          !res.json.advertisement_and_targeting_cookie
        ) {
          setShowCookieBanner(true);
        }
      })
      .catch((err) => {
        // handle error
      });
  };

  useEffect(() => {
    const controller = new AbortController();
    getCookieContent("", controller.signal);
    getCookieConsentData();
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    let controller = new AbortController();
    getLogo("", controller.signal)
      .then((res) => {
        let { logo, splash_screen, favicon } = res.json;
        let currentFavIcon = document.getElementById("favicon");
        let currentSplashLogo = document.getElementById("splashlogo");

        let headicon1 = document.getElementById("apple-touch-icon");
        let headicon2 = document.getElementById("shortcuticon");
        let headicon3 = document.getElementById("microsofticon");

        store.dispatch({
          type: "SET_LOGO",
          payload: { logo: logo, splash: splash_screen, favicon: favicon },
        });

        if (headicon1 && favicon) {
          headicon1.href = favicon;
        }

        if (headicon2 && favicon) {
          headicon2.href = favicon;
        }

        if (headicon3 && favicon) {
          headicon3.href = favicon;
        }

        if (currentFavIcon && logo) {
          currentFavIcon.href = logo;
        }
        if (currentSplashLogo && splash_screen) {
          currentSplashLogo.src = splash_screen;
        }

        let currentManifest = document.getElementById("dynamic-manifest");
        if (currentManifest && splash_screen) {
          let tempManifest = { ...ManifestData };
          tempManifest.icons[0].src = splash_screen;
          tempManifest.icons[1].src = splash_screen;
          tempManifest.icons[2].src = splash_screen;

          const stringManifest = JSON.stringify(tempManifest);
          const blob = new Blob([stringManifest], { type: "application/json" });
          const manifestURL = URL.createObjectURL(blob);
          currentManifest.setAttribute("href", manifestURL);
        }
      })
      .catch((err) => {
        // handle error
      });
  }, []);

  //getting of menu items
  const getCategoriesList_ = async (pageNum) => {
    if (pageNum > 0) {
      //await-r
      await getCategoriesList(`paged=${pageNum}`)
        .then(async (res) => {
          const totalCats = res.response.headers.get("x-wp-total");
          // limits the total categories-lists-length to total-available-category-list-length based on api response data
          setMaxCategoriesLength(totalCats);
          //this contains array of seven categories from api response
          res = res.json;
          //await-r
          await setState((state) => ({
            ...state,
            categoriesArr: [
              ...state.categoriesArr,
              ...res.map((cat) => {
                return {
                  label: cat.name,
                  command: () => {
                    props.history.push(`/category/${cat.slug}`);
                  },
                  pathname: `/category/${cat.slug}`,
                  icon: cat.url,
                  name: cat.name,
                  term_id: cat.term_id,
                  color: cat.color || "#007ad9",
                  slug: cat.slug,
                };
              }),
            ],
          }));
          setPageNo((pageNo) => pageNo + 1);
        })
        .catch((err) => {});
    }
  };

  const getMostRecents_ = () => {
    const controller = new AbortController();
    const signal = controller.signal;
    getMostRecents("", signal)
      .then((res) => {
        if (res.json) {
          store.dispatch({ type: SET_MOST_RECENT_POSTS, payload: res.json });
        }
      })
      .catch((err) => {});
  };

  const getMostPopulars_ = () => {
    getMostPopulars("")
      .then((res) => {
        store.dispatch({
          type: SET_FEATURED_POSTS,
          payload: res.json.featured,
        });
        store.dispatch({
          type: SET_MOST_POPULAR_POSTS,
          payload: res.json.posts,
        });
      })
      .catch((err) => {});
  };

  const getLists_ = () => {
    const groupBy =
      store.getState().lists.groupBy.code === "all-groups"
        ? ""
        : `&series_group=${store.getState().lists.groupBy.code}`;
    getLists(
      `paged=1&per_page=10&sort_by=${
        store.getState().lists.sortBy.code
      }${groupBy}`
    )
      .then((res) => {
        if (10 >= res.response.headers.get("x-wp-total")) {
          store.dispatch({
            type: UPDATE_LISTS,
            payload: {
              isFinished: true,
            },
          });
        }

        res = res.json;
        store.dispatch({
          type: UPDATE_LISTS,
          payload: {
            isFetching: false,
            preloaded: true,
            nextPage: 2,
            listsArr: res.series,
          },
        });
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getMostRecents_();
    getMostPopulars_();
  }, [props.location]);

  // on mounting of the app
  useEffect(() => {
    // Google analytics
    const TractID = "UA-187502045-1";
    ReactGA.initialize(TractID);
    if (isTabletOrMobile) {
      store.dispatch({ type: SET_MENU_ICON_MODE, payload: false });
    }

    getLists_();
    setBrowserName(detect());
    if (window.matchMedia("(display-mode: minimal-ui)").matches) {
      store.dispatch({ type: PWA_WINDOW_ON, payload: true });
    } else {
      store.dispatch({ type: PWA_WINDOW_ON, payload: false });
    }
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      store.dispatch({ type: SAVE_INSTALL_PROPOSAL_EVENT, payload: e });
    });
  }, [isTabletOrMobile]);

  useEffect(() => {
    store.dispatch({ type: SET_BROWSER_NAME, payload: browserName.name });
  }, [browserName]);

  // after getting the menuItems
  useEffect(() => {
    if (state.categoriesArr.length + 1 < parseInt(maxCategoriesLength)) {
      getCategoriesList_(pageNo);
    }
  }, [pageNo]);

  //update the categories in redux
  useEffect(() => {
    if (state.categoriesArr.length >= maxCategoriesLength) {
      store.dispatch({
        type: SET_CATEGORIES_ARR,
        payload: state.categoriesArr,
      });
    }
  }, [state.categoriesArr, props.categories.categories]);

  //on getting of stored settings
  useEffect(() => {
    if (props.settings) {
      setState({
        ...state,
        staticMenuInactive: props.settings.menuIconMode,
      });
    }
  }, [props.settings]);

  const _getLinkTypes = () => {
    getLinkTypeData()
      .then((res) => {
        if (res.response.ok) {
          const linkTypes = _.values(res.json);
          store.dispatch({ type: SET_LINK_TYPES, payload: linkTypes });
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  useEffect(() => {
    _getLinkTypes();
  }, []);

  useEffect(() => {
    (async () => {
      let totalCatsWithPosts = 0;
      const controller = new AbortController();
      const signal = controller.signal;

      await getHomePosts(`paged=${1}&per_page=3`, signal)
        .then((res) => {
          totalCatsWithPosts = res.response.headers.get("x-wp-total");
          store.dispatch({
            type: SET_HOME_POSTS,
            payload: [
              // ...store.getState().world.homePosts,
              ...res.json.categories,
            ],
          });

          store.dispatch({
            type: SET_MOST_RECENT_ONE,
            payload: res.json.recent_post[0],
          });

          // pageNumber++;
        })
        .catch((err) => {});

      getCategoriesList_(pageNo);
      setLoading(false);
    })();
  }, [localStorage.getItem("userToken")]);
  // const firstTimeLoginSet = () => {
  // 	setFirstTimeLoading(!firstTimeLoading);
  // };
  const notify = (message) =>
    toast.dark(message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: { backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5" },
    });

  const _randomPost = () => {
    setRandomPageLoading(true);
    let query = "category_id=";
    if (localStorage.getItem("userToken")) {
      props.categories.authRandomLinkCategories.map((data) => {
        return (query = query + data + ",");
      });
    } else {
      props.categories.randomLinkCategories.map((data) => {
        return (query = query + data + ",");
      });
    }

    getCatRandomPost(query)
      .then((res) => {
        // document.getElementById("floaty-list").style.opacity = "0";
        setRandomPageLoading(false);
        if (props.settings.randomLinkBrowserOpenMode) {
          // notify("Opening Links Randomly by Window");
          popupWindow(
            `${res.json[0].user_submit_url}`,
            "name",
            window,
            window.innerWidth - 400,
            window.innerHeight - 150
          );
        } else {
          // notify("Opening Links Randomly by Page");
          props.history.push(`/link?L=${res.json[0].ID}`);
        }
      })
      .catch((err) => {});
  };

  const _openInNewTab = () => {
    setRandomPageLoading(true);
    notify("Opening Links Randomly by Page");
    let query = "category_id=";
    props.categories.randomLinkCategories.map((data) => {
      return (query = query + data + ",");
    });
    getCatRandomPost(query)
      .then(async (res) => {
        // document.getElementById("floaty-list").style.opacity = "0";
        setRandomPageLoading(false);
        popupWindow(
          `${res.json[0].user_submit_url}`,
          "name",
          window,
          window.innerWidth - 400,
          window.innerHeight - 150
        );
      })
      .catch((err) => {});
  };

  const onToggleMenu = (event) => {
    event.preventDefault();
    if (isDesktop()) {
      if (state.layoutMode === "overlay") {
        setState({
          ...state,
          overlayMenuActive: !state.overlayMenuActive,
        });
      } else if (state.layoutMode === "static") {
        localStorage.setItem(
          "settings",
          JSON.stringify({
            ...props.settings,
            menuIconMode: !state.staticMenuInactive,
          })
        );
        store.dispatch({
          type: SET_MENU_ICON_MODE,
          payload: !state.staticMenuInactive,
        });
        setState({
          ...state,
          staticMenuInactive: !state.staticMenuInactive,
        });
      }
    } else {
      const mobileMenuActive = state.mobileMenuActive;
      setState({
        ...state,
        mobileMenuActive: !mobileMenuActive,
      });
    }
  };

  const onMenuItemClick = (event) => {
    if (!event.item.items) {
      setState({
        ...state,
        overlayMenuActive: false,
        mobileMenuActive: false,
      });
    }
  };

  const popupWindow = (url, windowName, win, w, h) => {
    const y = win.top.outerHeight / 2 + win.top.screenY - h / 2;
    const x = win.top.outerWidth / 2 + win.top.screenX - w / 2;
    return win.open(
      url,
      windowName,
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
    );
  };

  // const RefreshMenu_ = () =>{
  //   if(state.categoriesArr.length >= maxCategoriesLength){
  //     getCategoriesList_(pageNo-1)
  //     addedList = state.categoriesArr
  //     store.dispatch({ type: SET_CATEGORIES_ARR, payload: state.categoriesArr })
  //    }
  // }

  const isDesktop = () => {
    return window.innerWidth > 1024;
  };

  if (props.settings.darkMode) {
    document.getElementsByTagName("body")[0].classList.add("dark-body");
  } else {
    document.getElementsByTagName("body")[0].classList.remove("dark-body");
  }

  const wrapperClass = classNames("layout-wrapper", "app-wrapper-div", {
    "layout-overlay": state.layoutMode === "overlay",
    "layout-static": state.layoutMode === "static",
    "layout-static-sidebar-inactive":
      state.staticMenuInactive && state.layoutMode === "static",
    "layout-overlay-sidebar-active":
      state.overlayMenuActive && state.layoutMode === "overlay",
    "layout-mobile-sidebar-active": state.mobileMenuActive,
    "dark-mode": props.settings.darkMode,
  });

  const [titleChange, setTitleChange] = useState(false);

  const _closePostModal = () => {
    store.dispatch({
      type: SET_POST_CARD_DATA_MODAL,
      payload: { postCardModal: false, activePostCardModal: null },
    });
    // setPostModalData({
    // 	showModal: false,
    // 	id: null
    // });
  };
  const titleVisibility = () => {
    setTitleChange(!titleChange);
  };

  const _setRandomLinkOpenType = () => {
    if (props.settings.randomLinkBrowserOpenMode) {
      notify("Opening Links Randomly by Window");
      store.dispatch({
        type: "SET_RANDOM_LINK_BROWSER_OPEN_MODE",
        payload: false,
      });
    } else {
      notify("Opening Links Randomly by Page");
      store.dispatch({
        type: "SET_RANDOM_LINK_BROWSER_OPEN_MODE",
        payload: true,
      });
    }
  };

  //tags bar fixed on homepage and single post page
  const isTagsBarFixed = volatileRoutes.includes(props.location.pathname);
  const isFilterBarFixed = volatileRoutes.includes(props.location.pathname);

  let navWidth = "4.5vw";

  if (isLaptop) {
    navWidth = "6.2vw";
  }

  if (browserName.name === "firefox") {
    navWidth = "4.7vw";
  }

  if (browserName.name === "firefox" && isLaptop) {
    navWidth = "6.7vw";
  }

  const isTagsBarFixedStatus =
    props.location.pathname === "" ||
    props.location.pathname === "/submit-link";
  const isFilterBarFixedStatus =
    props.location.pathname === "" ||
    props.location.pathname === "/submit-link";

  let newPath2 = props.history.location.pathname.split("/");
  let fin2 = "/" + newPath2[1];

  return (
    <>
      <RouteChangeTracker />
      {props.location.pathname === "/login" ? (
        <Route
          path="/login"
          exact
          render={() => <LoginForm history={props.history} />}
        />
      ) : (
        <div className="main-app-container">
          <div className="hue-bar" id="app-hue-bar">
            &nbsp;&nbsp;
          </div>
          <div className={wrapperClass}>
            {/* <img src={`https://api.miniature.io/?token=${process.env.REACT_APP_IMAGE_PREVIEW_KEY}&url=bbc.com`} /> */}
            {/* <button onClick={firstTimeLoginSet}>load</button> */}
            <AppTopbar
              browserName={browserName}
              setSearchPageNumber={setSearchPageNumber}
              setPageNo={setPageNo}
              onToggleMenu={onToggleMenu}
              parentState={state}
              titleVisibility={titleVisibility}
              isAuth={props.user.user}
              showCookieBanner={showCookieBanner}
              setShowCookieBanner={setShowCookieBanner}
            />
            <div
              style={{
                height: "55px",
                backgroundColor: "#017ad9",
              }}
              className="topbar-nepathya"
            ></div>
            <div
              className="layout-sidebar"
              style={
                !iconMode
                  ? {
                      // scrollbarWidth: "thin",
                      background: "transparent",
                      paddingLeft: "7px",
                    }
                  : {
                      // scrollbarWidth: "thin",
                      background: "transparent",
                      paddingLeft: "7px",
                      width: navWidth,
                    }
              }
            >
              {/* state.categoriesArr.length >= maxCategoriesLength ? (state.categoriesArr):[] */}
              {/* {props.categories.categories.length>=maxCategoriesLength ? (
            <AppMenu staticMenuInactive={state.staticMenuInactive}  model={props.categories.categories} onMenuItemClick={onMenuItemClick} />             
            ):( */}
              {/* state.categoriesArr.length >= maxCategoriesLength ? (state.categoriesArr):([]) */}
              <AppMenu
                staticMenuInactive={state.staticMenuInactive}
                subscribeShow={titleChange}
                model={
                  state.categoriesArr.length >= 11
                    ? state.categoriesArr
                    : [
                        {
                          label: "All Categories",
                          command: () => {
                            props.history.push(`/`);
                          },
                          pathname: "/",
                          icon: "",
                          term_id: 884886,
                          color: "#8A8A8A",
                          slug: "all",
                        },
                      ]
                }
                onMenuItemClick={onMenuItemClick}
                setShowCookieBanner={setShowCookieBanner}
                showCookieBanner={showCookieBanner}
              />
              {/* )} */}
            </div>
            <div
              className={`menuitem-custom-tooltip ${
                !state.mobileMenuActive && state.staticMenuInactive
                  ? "visible"
                  : "invisible"
              }`}
            >
              Tooltip
            </div>
            <AcceptCookies
              showCookieBanner={showCookieBanner}
              setShowCookieBanner={setShowCookieBanner}
            />
            {/* Tags bar fixed on homepage and favourites page */}
            {isTagsBarFixedStatus ? (
              props.settings.tagsMode ? (
                props.history.location.pathname !== "/submit-link" ||
                props.history.location.pathname !== "/account" ||
                props.history.location.pathname !== "/subscription" ? (
                  <div
                    style={
                      {
                        // marginTop: !props.settings.filterBar ? "0px" : "50px"
                        // paddingTop: "10px"
                      }
                    }
                  >
                    <TagsBar
                      postsModal={false}
                      darkMode={props.settings.darkMode}
                      iconMode={iconMode}
                      staticMenuInactive={state.staticMenuInactive}
                      isTagsBarFixedStatus={isTagsBarFixedStatus}
                    />
                  </div>
                ) : null
              ) : null
            ) : null}
            {/* {props.settings.filterBar ? (
							props.location.pathname === "/add-link" ||
							props.location.pathname === "/lists" ||
							props.location.pathname === "/icon-library" ||
							props.location.pathname === "/info" ||
							props.location.pathname === "/terms" ||
							props.location.pathname === "/faq" ||
							props.location.pathname === "/account" ||
							props.location.pathname === "/subscription" ||
							props.location.pathname === "/privacy" ? null : (
								<div style={{ marginTop: props.settings.tagsMode ? "50px" : "0px" }}>
									<FilterBar iconMode={iconMode} darkMode={props.settings.darkMode} staticMenuInactive={state.staticMenuInactive} isTagsBarFixedStatus={isTagsBarFixedStatus} />
								</div>
							)
						) : null} */}
            {isFilterBarFixedStatus ? (
              props.settings.filterBar ? (
                props.history.location.pathname === "/submit-link" ||
                props.history.location.pathname === "/account" ||
                props.history.location.pathname === "/subscription" ||
                props.history.location.pathname === "/activity" ||
                props.history.location.pathname === "/icon-library" ||
                props.history.location.pathname === "/how-to" ||
                props.history.location.pathname === "/platforms" ||
                props.history.location.pathname === "/" ||
                props.history.location.pathname === "/favorites" ? null : (
                  <div
                    style={{
                      marginTop: props.settings.tagsMode ? "50px" : "0px",
                    }}
                  >
                    <FilterBar
                      bannersMode={props.settings.bannersMode}
                      iconMode={iconMode}
                      darkMode={props.settings.darkMode}
                      staticMenuInactive={state.staticMenuInactive}
                      isFilterBarFixedStatus={isFilterBarFixedStatus}
                    />
                  </div>
                )
              ) : null
            ) : null}
            {props.history.location.pathname === "/account" ||
            props.history.location.pathname === "/subscription" ||
            props.history.location.pathname === "/activity" ? (
              <AccountTagsBar
                pathname={props.history.location.pathname}
                iconMode={iconMode}
              />
            ) : null}
            {props.history.location.pathname === "/info" ||
            props.history.location.pathname === "/faq" ||
            props.history.location.pathname === "/terms" ||
            props.history.location.pathname === "/privacy" ||
            props.history.location.pathname === "/cookies" ||
            props.history.location.pathname === "/disclaimer" ||
            props.history.location.pathname === "/eula" ||
            props.history.location.pathname === "/use" ? (
              <InfoBar
                pathname={props.history.location.pathname}
                iconMode={iconMode}
                darkMode={props.settings.darkMode}
                staticMenuInactive={state.staticMenuInactive}
                isTagsBarFixedStatus={isTagsBarFixedStatus}
              />
            ) : null}
            <div className="layout-main">
              {/* Tags bar not fixed on other pages */}
              {!isTagsBarFixedStatus ? (
                props.settings.tagsMode ? (
                  props.history.location.pathname !== "/account" ||
                  props.history.location.pathname !== "/subscription" ||
                  props.history.location.pathname !== "/activity" ||
                  props.history.location.pathname !== "/terms" ||
                  props.history.location.pathname !== "/faq" ||
                  props.history.location.pathname !== "/info" ||
                  props.history.location.pathname !== "/howto" ||
                  props.history.location.pathname !== "/privacy" ||
                  props.history.location.pathname !== "/cookies" ||
                  props.history.location.pathname !== "/disclaimer" ||
                  props.history.location.pathname !== "/eula" ||
                  props.history.location.pathname !== "/use" ? (
                    <div>
                      <TagsBar
                        postsModal={false}
                        darkMode={props.settings.darkMode}
                        iconMode={iconMode}
                        staticMenuInactive={state.staticMenuInactive}
                        isTagsBarFixedStatus={isTagsBarFixedStatus}
                      />
                    </div>
                  ) : null
                ) : null
              ) : null}
              {!isFilterBarFixedStatus ? (
                props.settings.filterBar ? (
                  props.history.location.pathname === "/account" ||
                  props.history.location.pathname === "/subscription" ||
                  props.history.location.pathname === "/activity" ||
                  props.history.location.pathname === "/terms" ||
                  props.history.location.pathname === "/faq" ||
                  props.history.location.pathname === "/info" ||
                  props.history.location.pathname === "/privacy" ||
                  props.history.location.pathname === "/cookies" ||
                  props.history.location.pathname === "/disclaimer" ||
                  props.history.location.pathname === "/eula" ||
                  props.history.location.pathname === "/use" ||
                  props.history.location.pathname === "/lists" ||
                  props.history.location.pathname === "/icon-library" ||
                  props.history.location.pathname === "/platforms" ||
                  props.history.location.pathname === "/how-to" ||
                  props.history.location.pathname === "/submit-link" ||
                  fin2 === "/link" ? null : (
                    <div
                      style={{
                        flex: 1,
                        marginTop:
                          props.history.location.pathname === "/favorites"
                            ? "0px"
                            : "0px",
                      }}
                    >
                      <FilterBar
                        iconMode={iconMode}
                        bannersMode={props.settings.bannersMode}
                        darkMode={props.settings.darkMode}
                        staticMenuInactive={state.staticMenuInactive}
                        isFilterBarFixedStatus={isFilterBarFixedStatus}
                      />
                    </div>
                  )
                ) : null
              ) : null}

              {/* Prev */}
              <div
                className={`app-route-wrapper ${
                  isTagsBarFixed && props.settings.tagsMode
                    ? "with-tagsbar-fixed"
                    : ""
                }  ${
                  isFilterBarFixed && props.settings.filterBar
                    ? props.settings.tagsMode
                      ? "with-filterbar-and-tagsbar-fixed"
                      : "testtt"
                    : ""
                }`}
              >
                <Switch>
                  <Route
                    path="/"
                    exact
                    render={() => (loading ? <LoaderPage /> : <NewDashboard />)}
                  />
                  <Route
                    path="/search-result"
                    exact
                    render={() => (
                      <SearchResult
                        setSearchPageNumber={setSearchPageNumber}
                        searchPageNumber={searchPageNumber}
                      />
                    )}
                  />
                  <Route
                    path="/category/:catSlug"
                    exact
                    render={(props) =>
                      loading ? (
                        <LoaderPage />
                      ) : (
                        <Suspense fallback={<SuspenseLoader />}>
                          <Category {...props} />
                        </Suspense>
                      )
                    }
                    key={props.location.pathname}
                  />
                  <Route path="/submit-link" exact render={() => <AddPost />} />
                  <Route
                    path="/favorites"
                    exact
                    render={(props) => (
                      <Suspense fallback={<SuspenseLoader />}>
                        <Favourites {...props} titleVisibility={titleChange} />
                      </Suspense>
                    )}
                  />
                  <Route
                    path="/popular-links"
                    exact
                    render={() => (
                      <Suspense fallback={<SuspenseLoader />}>
                        <MostPopulars />
                      </Suspense>
                    )}
                  />
                  <Route
                    path="/recent-links"
                    exact
                    render={() => (
                      <Suspense fallback={<SuspenseLoader />}>
                        <MostRecents />
                      </Suspense>
                    )}
                  />
                  <Route path="/link" exact render={() => <Post />} />
                  <Route
                    path="/:id"
                    exact
                    render={() => {
                      if (
                        isEmpty(props.location.key) &&
                        parseInt(props.location.pathname.substring(1))
                      ) {
                        return <Post />;
                      } else {
                        if (
                          props.location.pathname === "/lists" &&
                          !isEmpty(props.location.key)
                        ) {
                          return (
                            <Suspense fallback={<SuspenseLoader />}>
                              <Lists />
                            </Suspense>
                          );
                        } else if (
                          props.location.pathname === "/how-to" &&
                          !isEmpty(props.location.key)
                        ) {
                          return (
                            <Suspense fallback={<SuspenseLoader />}>
                              <Howto />
                            </Suspense>
                          );
                        } else if (
                          props.location.pathname === "/platforms" &&
                          !isEmpty(props.location.key)
                        ) {
                          return (
                            <Suspense fallback={<SuspenseLoader />}>
                              <Platforms />
                            </Suspense>
                          );
                        } else if (
                          props.location.pathname === "/info" &&
                          !isEmpty(props.location.key)
                        ) {
                          return (
                            <Suspense fallback={<SuspenseLoader />}>
                              <About />
                            </Suspense>
                          );
                        } else if (
                          props.location.pathname === "/faq" &&
                          !isEmpty(props.location.key)
                        ) {
                          return (
                            <Suspense fallback={<SuspenseLoader />}>
                              <Faq />
                            </Suspense>
                          );
                        } else if (
                          props.location.pathname === "/terms" &&
                          !isEmpty(props.location.key)
                        ) {
                          return (
                            <Suspense fallback={<SuspenseLoader />}>
                              <TermsAndConditions />
                            </Suspense>
                          );
                        } else if (
                          props.location.pathname === "/privacy" &&
                          !isEmpty(props.location.key)
                        ) {
                          return (
                            <Suspense fallback={<SuspenseLoader />}>
                              <PrivacyPolicy />
                            </Suspense>
                          );
                        } else if (
                          props.location.pathname === "/cookies" &&
                          !isEmpty(props.location.key)
                        ) {
                          return (
                            <Suspense fallback={<SuspenseLoader />}>
                              <Cookies />
                            </Suspense>
                          );
                        } else if (
                          props.location.pathname === "/disclaimer" &&
                          !isEmpty(props.location.key)
                        ) {
                          return (
                            <Suspense fallback={<SuspenseLoader />}>
                              <Disclaimer />
                            </Suspense>
                          );
                        } else if (
                          props.location.pathname === "/eula" &&
                          !isEmpty(props.location.key)
                        ) {
                          return (
                            <Suspense fallback={<SuspenseLoader />}>
                              <Eula />
                            </Suspense>
                          );
                        } else if (
                          props.location.pathname === "/use" &&
                          !isEmpty(props.location.key)
                        ) {
                          return (
                            <Suspense fallback={<SuspenseLoader />}>
                              <Use />
                            </Suspense>
                          );
                        } else if (
                          props.location.pathname === "/account" &&
                          !isEmpty(props.location.key)
                        ) {
                          return (
                            <Suspense fallback={<SuspenseLoader />}>
                              <Account darkMode={darkMode} />
                            </Suspense>
                          );
                        } else if (
                          props.location.pathname === "/subscription" &&
                          !isEmpty(props.location.key)
                        ) {
                          return <Subscription />;
                        } else if (
                          props.location.pathname === "/activity" &&
                          !isEmpty(props.location.key)
                        ) {
                          return <Submissions {...props} />;
                        } else {
                          return <NotFound />;
                        }
                      }
                    }}
                  />
                  <Route
                    path="/info"
                    exact
                    render={() => (
                      <Suspense fallback={<SuspenseLoader />}>
                        <About />
                      </Suspense>
                    )}
                  />
                  <Route
                    path="/platforms"
                    exact
                    render={() => (
                      <Suspense fallback={<SuspenseLoader />}>
                        <Platforms />
                      </Suspense>
                    )}
                  />
                  <Route
                    path="/terms"
                    exact
                    render={() => (
                      <Suspense fallback={<SuspenseLoader />}>
                        <TermsAndConditions />
                      </Suspense>
                    )}
                  />
                  <Route
                    path="/privacy"
                    exact
                    render={() => (
                      <Suspense fallback={<SuspenseLoader />}>
                        <PrivacyPolicy />
                      </Suspense>
                    )}
                  />
                  <Route
                    path="/cookies"
                    exact
                    render={() => (
                      <Suspense fallback={<SuspenseLoader />}>
                        <Cookies />
                      </Suspense>
                    )}
                  />
                  <Route
                    path="/use"
                    exact
                    render={() => (
                      <Suspense fallback={<SuspenseLoader />}>
                        <Use />
                      </Suspense>
                    )}
                  />
                  <Route
                    path="/eula"
                    exact
                    render={() => (
                      <Suspense fallback={<SuspenseLoader />}>
                        <Eula />
                      </Suspense>
                    )}
                  />
                  <Route
                    path="/disclaimer"
                    exact
                    render={() => (
                      <Suspense fallback={<SuspenseLoader />}>
                        <Disclaimer />
                      </Suspense>
                    )}
                  />
                  <Route
                    path="/send-feedback"
                    exact
                    render={() => <SendFeedback />}
                  />
                  <Route
                    path="/lists"
                    exact
                    render={() => (
                      <Suspense fallback={<SuspenseLoader />}>
                        <Lists />
                      </Suspense>
                    )}
                  />
                  <Route
                    path="/lists/:listSlug"
                    exact
                    render={() => <List />}
                  />
                  <Route
                    path="/faq"
                    exact
                    render={() => (
                      <Suspense fallback={<SuspenseLoader />}>
                        <Faq />
                      </Suspense>
                    )}
                  />
                  <Route
                    path="/how-to"
                    exact
                    render={() => (
                      <Suspense fallback={<SuspenseLoader />}>
                        <Howto />
                      </Suspense>
                    )}
                  />
                  <Route
                    path="/tags/:tagSlug"
                    exact
                    render={(p) => <Tag key={p.match.params.tagSlug} {...p} />}
                  />
                  <Route
                    path="/playground"
                    exact
                    render={(p) => (
                      <Suspense fallback={<SuspenseLoader />}>
                        <Playground {...p} />
                      </Suspense>
                    )}
                  />
                  <Route
                    path="/offline"
                    exact
                    render={(p) => <OfflinePage {...p} />}
                  />
                  <Route
                    path="/icon-library"
                    exact
                    render={(p) => <Tags {...p} />}
                  />
                  <Route
                    path="/account"
                    exact
                    render={() => (
                      <Suspense fallback={<SuspenseLoader />}>
                        <Account darkMode={darkMode} />
                      </Suspense>
                    )}
                  />
                  <Route
                    path="/subscription"
                    exact
                    render={() => <Subscription />}
                  />
                  <Route
                    path="/activity"
                    exact
                    render={() => <Submissions {...props} />}
                  />
                  <Route
                    path="/unsubscribe"
                    exact
                    render={() => <Unsubscribe {...props} />}
                  />
                  <Route
                    path="/verify_subscription"
                    exact
                    render={() => (
                      <Suspense fallback={<SuspenseLoader />}>
                        <SubscribeConfirmation {...props} />
                      </Suspense>
                    )}
                  />
                  <Route component={NotFound} />
                </Switch>
              </div>
            </div>
            <ToastContainer />
          </div>
          <div className="outer-circle">
            <NewRandomLink
              darkMode={props.settings.darkMode}
              openInNewTab={_openInNewTab}
              onRandomClick={_randomPost}
              isAuth={props.user.user}
              randomLinkCategories={props.categories.randomLinkCategories}
              authRandomLinkCategories={
                props.categories.authRandomLinkCategories
              }
              randomPageLoading={randomPageLoading}
              setRandomLinkOpenType={_setRandomLinkOpenType}
              categories={props.categories}
              randomLinkBrowserOpenMode={
                props.settings.randomLinkBrowserOpenMode
              }
            />
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            {/* <div onClick={_randomPost} className="random-post-button inner-circle">
							{randomPageLoading ? <Icon loading name="spinner" /> : <Icon name="shuffle" />}
						</div>
						<span></span>
						<span></span>
						<span></span>
						<span></span> */}
          </div>
          <PostModal
            setModalRef={(ref) =>
              store.dispatch({ type: "SET_MODAL_REF", payload: ref })
            }
            open={props.postCardModal}
            postData={props.postModalData}
            onClose={() => _closePostModal()}
          />
          <UninstallPwaModal
            open={props.pwa.uninstallPwaModal}
            onClose={() =>
              store.dispatch({ type: UNINSTALL_PWA_MODAL, payload: false })
            }
          />
          <SendFeedbackModal
            onClose={() => {
              store.dispatch({ type: SET_FEEDBACK_MODAL, payload: false });
            }}
            open={props.world.feedbackModal}
          />
          <IconLibraryModal />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  settings: state.settings,
  categories: state.categories,
  user: state.auth,
  world: state.world,
  postCardModal: state.world.postCardModal,
  activePostCardModal: state.world.activePostCardModal,
  postModalData: state.world.postModalData,
  pwa: state.pwaInstall,
  logo: state.world.logo,
});

export default withRouter(connect(mapStateToProps, {})(App));

// working
//<AppMenu staticMenuInactive={state.staticMenuInactive}  model={state.categoriesArr.length >= maxCategoriesLength ? (state.categoriesArr):(InitialArr)} onMenuItemClick={onMenuItemClick} />
