import React, { useRef, useState, useEffect, createRef } from "react";
import { gsap } from "gsap";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import "./style.scss";

const SlidingMenu = (props) => {
  const darkMode = useSelector((state) => state.settings.darkMode);

  const items = [...props.items];

  const rootRef = useRef();
  const indicator1 = useRef();
  const indicator2 = useRef();
  const itemsRef = useRef(items.map(createRef));
  const [active, setActive] = useState(0);
  let isMobile = window.innerWidth <= 650;

  useEffect(() => {
    if (props.location && props.location.pathname) {
      let pathname = props.location.pathname || "";
      let activeOption = items.filter((it) => it.href === pathname);
      if (activeOption && activeOption[0]) {
        setActive(activeOption[0].id);
      }
    }
  }, [props.location]);

  const animate = () => {
    const menuOffset = rootRef.current.getBoundingClientRect();
    const activeItem = itemsRef.current[active].current;
    const { width, height, top, left } = activeItem.getBoundingClientRect();

    const settings = {
      x: left - menuOffset.x,
      y: top - menuOffset.y,
      width: width,
      height: height,
      backgroundColor: darkMode ? "#3c3c3c" : "#edf0f5",
      ease: "elastic.out(.7, .7)",
      color: "#fff !important",
      duration: 0.8,
    };

    gsap.to(indicator1.current, {
      ...settings,
    });

    gsap.to(indicator2.current, {
      ...settings,
      duration: 0.85,
    });
  };

  useEffect(() => {
    animate();
    window.addEventListener("resize", animate);

    return () => {
      window.removeEventListener("resize", animate);
    };
  }, [active]);

  const _scrollLeft = () => {
    const scrollContainer = document.getElementById("horizontal-menu-scroll");
    const lft = document.getElementById("scroll-left");
    const rig = document.getElementById("scroll-right");

    if (scrollContainer) {
      if (scrollContainer.scrollLeft === 0) {
        lft.classList.add("scroll-hide");
      } else {
        lft.classList.remove("scroll-hide");
        scrollContainer.scrollLeft -= 40;
      }

      if (scrollContainer.scrollLeft < 290) {
        rig.classList.remove("scroll-hide");
      } else {
        rig.classList.add("scroll-hide");
      }
    }
  };

  const _scrollRight = () => {
    const scrollContainer = document.getElementById("horizontal-menu-scroll");
    const lft = document.getElementById("scroll-left");
    const rig = document.getElementById("scroll-right");

    if (scrollContainer) {
      if (scrollContainer.scrollLeft === 0) {
        lft.classList.add("scroll-hide");
      } else {
        lft.classList.remove("scroll-hide");
      }

      if (scrollContainer.scrollLeft >= 290) {
        rig.classList.add("scroll-hide");
      } else {
        rig.classList.remove("scroll-hide");
      }
      scrollContainer.scrollLeft += 40;
    }
  };

  return (
    <>
      <div
        ref={rootRef}
        className="menu horizontal-menu-scroll"
        id="horizontal-menu-scroll"
      >
        {items.map((item, index) => (
          <div
            key={item.name}
            ref={itemsRef.current[index]}
            className={`item ${active === index ? "active" : ""} ${
              darkMode ? "dark-info" : "light-info"
            }`}
            onClick={() => {
              setActive(index);
              props.history.push(`${item.href}`);
            }}
          >
            {item.name}
          </div>
        ))}
      </div>
      {isMobile && (
        <div
          onClick={_scrollLeft}
          id="scroll-left"
          className={`${
            darkMode ? `info-indicator-left` : `info-indicator-left-light`
          }`}
        >
          <i className={`chevron left small icon`}></i>
        </div>
      )}

      {isMobile && (
        <div
          onClick={_scrollRight}
          id="scroll-right"
          className={
            darkMode ? `info-indicator-right` : `info-indicator-right-light`
          }
        >
          <i className={`chevron right small icon`}></i>
        </div>
      )}
    </>
  );
};

export default withRouter(SlidingMenu);
